import {
  Button,
  Card,
  Group,
  Stack,
  Text,
  Title,
  Grid,
  Divider,
  Center,
  Box,
  Modal,
} from "@mantine/core";
import {
  getPackages,
  getPTPackages,
  getUserPackages,
  getRenwalPackages,
  getTransactions,
  getFounderRenwalPackages,
  getUserProfile,
} from "../endpoints/api";
import { format, add, parseISO, isValid, differenceInWeeks } from "date-fns";

import { useQuery, useIsFetching } from "react-query";
import { useEffect, useRef, useMemo } from "react";
import PackagesCard from "../Components/Packages/PackagesCard";
import { SegmentedControl } from "@mantine/core";
import { useState } from "react";
import { useGetProfile, useGeUserPackages } from "../api/auth";
import axios from "axios";
import { api } from "../utils/api";
import { T, useT, useLang } from "../i18n/index";
import { use } from "i18next";
import { PackagesStyle } from "../Styles";
import { GetInfinitePagesInterface } from "../interfaces";
import { useAuth } from "./../utils/auth";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

import Loader from "../Components/Loader";
import { Navigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { NULL } from "sass";
import { Link, useParams, useLocation } from "react-router-dom";
import { sortByOrderTag } from "./../utils/helper";
import { apiRoutes } from "./../routes";
import { pathToUrl } from "./../utils/router";
import { useFetch } from "./../utils/reactQuery";

declare var Spreedly: any;
declare var Frames: any;
declare var TabbyCard: any;
function Packages() {
  const t = useT();

  const [opened, setOpened] = useState(false);
  const [m3dsModal, setm3dsModal] = useState(false);
  const [displayMada, setDisplayMada] = useState(false);
  const [displayTabby, setDisplayTabby] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [payButtonStatus, setPayButtonStatus] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("visa_master");
  const [bundle, setBundle] = useState<any>();
  const { data: user, isLoading: isUserLoading } = useGetProfile();
  const [purchaseStatus, setPurchaseStatus] = useState("idle");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [payment3dsLoading, setPayment3dsLoading] = useState(false);
  const [startsAtDate, setStartsAtDate] = useState("");
  const lang = useLang();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlQuery = new URLSearchParams(search);
  const stateParam = urlQuery.get("test");

  const { data: transactions } = useQuery(
    ["transactions"],
    () => getTransactions(),
    {
      refetchOnWindowFocus: true,
    }
  );

  const { data: userPackages } = useQuery(
    ["userPackages"],
    () => getUserPackages(),
    {
      refetchOnWindowFocus: true,
    }
  );

  const isValidPackage = ({ service_package, refunded }: any) => {
    return (
      !refunded &&
      (service_package?.subscribable ||
        service_package?.name.toLowerCase().includes("access") ||
        service_package?.name.toLowerCase().includes("staff plan") ||
        service_package?.name.toLowerCase().includes("عضوية الموظفين") ||
        service_package?.name.toLowerCase().includes("تجديد") ||
        service_package?.name.toLowerCase().startsWith("اشتراك"))
    );
  };

  const userServicesPackages = useMemo(() => {
    return userPackages?.client_service_packages.filter((p: any) =>
      isValidPackage(p)
    );
  }, [userPackages]);

  const currentActivePackage = useMemo(() => {
    return userPackages?.client_service_packages
      ?.reverse()
      .find((p: any) => isValidPackage(p));
  }, [userPackages]);

  const hasSubscribableService = useMemo(() => {
    return userPackages?.client_service_packages
      ?.reverse()
      .find(({ service_package, refunded }: any) => {
        return service_package?.subscribable && !refunded;
      });
  }, [userPackages]);

  const freezePackage = useMemo(() => {
    return userPackages?.client_service_packages
      ?.reverse()
      .find(({ service_package, refunded }: any) => {
        return service_package?.name.toLowerCase().includes("freezing");
      });
  }, [userPackages]);

  useEffect(() => {
    if (
      currentActivePackage &&
      currentActivePackage.expires_at &&
      !bundle?.pt_package
    ) {
      const parsedDate = parseISO(currentActivePackage.expires_at);

      if (isValid(parsedDate)) {
        const weeksInName = freezePackage?.service_package?.name?.split(" ")[0];
        const assigned_at = parseISO(freezePackage?.assigned_at);
        const expires_at = parseISO(freezePackage?.expires_at);
        const freezeInWeeks = differenceInWeeks(expires_at, assigned_at);

        const weeks =
          weeksInName === freezeInWeeks ? freezeInWeeks : weeksInName;
        const toAdd = freezePackage && weeks ? { weeks, days: 1 } : { days: 1 };
        const startsAtDate = add(parsedDate, toAdd);
        const formattedDate = format(startsAtDate, "yyyy-MM-dd");
        setStartsAtDate(formattedDate);
        // globalFormData["starts_at"] = formattedDate;
      }
    } else {
      setStartsAtDate("");
    }
  }, [currentActivePackage, bundle, freezePackage]);

  // console.log(userServicesPackages);

  const {
    data,
    status,
    isLoading,
    isError,
    isFetched,
    refetch,
    isPreviousData,
  } = useQuery(
    ["packages"],
    () => {
      if (userServicesPackages && userServicesPackages.length !== 0) {
        if (userServicesPackages.length === 1 && !hasSubscribableService) {
          return getRenwalPackages(auth?.user?.club_id, user?.home_location_id);
        } else {
          //logic to load Renewal Packages to ND
        }
      } else if (!userServicesPackages || userServicesPackages.length === 0) {
        return getPackages(auth?.user?.club_id, user?.home_location_id);
      }
    },
    {
      // keepPreviousData: true,
      enabled: !!userPackages && !!user && !!transactions,
      refetchOnWindowFocus: true,
    }
  );
  const auth = useAuth();
  const { classes } = PackagesStyle();

  const {
    data: packages,
    status: ptStatus,
    isLoading: ptIsLoading,
    isError: ptError,
    isFetched: ptIsFetched,
    refetch: ptRefetch,
    isPreviousData: ptIsPrevious,
  } = useQuery(
    ["ptpackages"],
    () => {
      if (userServicesPackages && userServicesPackages?.length !== 0) {
        return (async () => {
          const pt_packages = await getPTPackages(
            auth?.user?.club_id,
            user?.home_location_id
          );
          pt_packages.service_packages = pt_packages?.service_packages?.map(
            (p: any) => ({
              ...p,
              pt_package: true,
            })
          );
          return pt_packages;
        })();
        // return getPTPackages(auth?.user?.club_id, user?.home_location_id);
      }
    },
    {
      // keepPreviousData: true,
      enabled: !!userPackages && !!user,
      refetchOnWindowFocus: true,
    }
  );

  const _3dsModalRef = useRef<HTMLDivElement>(null);

  const largeScreen = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    setDisplayTabby(false);
    setDisplayMada(false);
  }, [paymentMethod]);

  const handlePayment = () => {
    submitFisikalFormSecondStep();
  };

  const ONE_MONTH_RECURRING_IDS = [
    57, 65, 66, 67, 270, 276, 272, 126, 127, 128,
  ];

  const isMonthlyRecurringBundle =
    bundle?.type === "pt_package" ||
    bundle?.subscribable ||
    bundle?.items?.find((i: any) => i.freq === "month") ||
    bundle?.items?.find((i: any) => i.freq === "day") ||
    ONE_MONTH_RECURRING_IDS.includes(bundle?.id);

  const cost = bundle?.cost?.amount && bundle?.cost?.amount / 100;
  const custom_price =
    bundle?.custom_price?.amount && bundle?.custom_price?.amount / 100;

  const globalFormData = {
    client_id: user?.id,
    starts_at: startsAtDate,
    name: user?.name,
    email: user?.email,
    spackageId: bundle?.id,
    type: bundle?.type,
    club_id: auth?.user?.club_id,
    gym_location: user?.home_location_id,
    amount: cost || custom_price,
    payment_method: paymentMethod,
    package_name: bundle?.name,
    bundle_id: bundle?.id,
    product_type: bundle?.items ? "bundle" : "service_package",
    packageDetails: {
      id: bundle?.id,
      title: bundle?.name,
      amount: cost || custom_price,
      location: auth?.user?.club_name,
    },
  };

  const displaySpreedlyFrom = (response: any) => {
    setPaymentLoading(true);

    window?.SpreedlyExpress.init(
      response.key,
      {
        amount: response.amount,
        company_name: response.company_name,
        full_name: response.full_name,
      },
      {
        email: response.email,
      }
    );

    window?.SpreedlyExpress.onPaymentMethod(function (
      token: any,
      paymentMethod: any
    ) {
      let browser_info = window?.Spreedly.ThreeDS.serialize("05", "text/html");
      var data = {
        order_id: response.order_id,
        payment_provider: response.payment_provider,
        url_schema: response.url_schema,
        token: token,
        browser_info: browser_info,
      };
      setm3dsModal(true);
      setPayment3dsLoading(true);
      setOpened(false);
      setPaymentLoading(false);

      api
        .post("subscription-visa-confirm-checkout", data)
        .then((response: any) => {
          confirmCheckout(response.data);
        });

      // api(data, confirmCheckout);
    });

    window?.SpreedlyExpress.onViewClose(function () {
      setOpened(false);
      setPaymentLoading(false);
    });

    window?.SpreedlyExpress.openView();
  };
  //@TODO
  const confirmCheckout = (data: any) => {
    const _3dsmodalbody = document.getElementById("3ds-modal-body")!;

    if (_3dsmodalbody) {
      // _3dsmodalbody.innerHTML = `<div id="device-fingerprint" className="hidden"></div><div id="challenge-modal"><div  id="challenge"></div></div>`;
    }

    var response = data["message"];
    if (response.requires_action) {
      setPaymentMethod("visa_master");
      setOpened(false);
      // window?.SpreedlyExpress.closeView();
      window?.SpreedlyExpress.unload();

      setm3dsModal(true);
      setPayment3dsLoading(false);
      setPaymentLoading(false);

      var lifecycle = new Spreedly.ThreeDS.Lifecycle({
        environmentKey: response.key,
        hiddenIframeLocation: "device-fingerprint",
        challengeIframeLocation: "challenge",
        transactionToken: response.transaction_token,
        challengeIframeClasses: "fitToModal",
      });

      var on3DSstatusUpdatesFn = function (threeDsStatusEvent: any) {
        if (threeDsStatusEvent.action === "succeeded") {
          var data = {
            order_id: response.order_id,
            payment_provider: response.payment_provider,
            url_schema: response.url_schema,
            status: `threeDsStatusEvent.action`,
            transaction_token: response.transaction_token,
          };

          api
            .post("subscription-visa-complete-checkout", data)
            .then((response: any) => {
              setPayment3dsLoading(false);
              fisikalCompleteCheckout(response.data);
            });
        } else if (threeDsStatusEvent.action === "error") {
          setPayment3dsLoading(false);
          setErrorMessage(threeDsStatusEvent.context);
        }
      };
      // Setup event handling and kickoff 3DSecure lifecycle
      Spreedly.on("3ds:status", on3DSstatusUpdatesFn);
      lifecycle.start();
    } else {
      setPaymentMethod("visa_master");
      setPayment3dsLoading(false);
      setm3dsModal(false);
    }
  };

  const fisikalCompleteCheckout = (data: any) => {
    var response = data["message"];
    if (response.message == "ok") {
      setOpened(false);
      setPaymentMethod("visa_master");
      setPurchaseStatus("fullfilled");
      navigate("/packages/success");
    } else {
      setPurchaseStatus("rejected");
      setOpened(false);
      setPaymentMethod("visa_master");
    }
  };

  const submitFisikalFormSecondStep = () => {
    if (paymentMethod == "mada") {
      setDisplayMada(true);
      setPaymentLoading(true);
      Frames.init({
        publicKey: "pk_edf825c0-6c39-4aee-8053-ac3ca0038203",
      });
      Frames.addEventHandler(
        Frames.Events.CARD_VALIDATION_CHANGED,
        onCardValidationChanged
      );
      Frames.addEventHandler(Frames.Events.CARD_SUBMITTED, cardSubmitted);

      Frames.addEventHandler(
        Frames.Events.CARD_TOKENIZATION_FAILED,
        onCardTokenizationFailed
      );

      Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, onCardTokenized);

      submitFisikalFormSecondStepMadaPayment();
      // submitFisikalFormSecondStepMadaPayment(formData);
    } else if (paymentMethod == "tabby") {
      const cost = bundle?.cost?.amount && bundle?.cost?.amount / 100;
      const custom_price =
        bundle?.custom_price?.amount && bundle?.custom_price?.amount / 100;
      setDisplayTabby(true);

      new TabbyCard({
        selector: "#tabbyCard", // empty div for TabbyCard
        currency: "SAR", // or SAR, BHD, KWD
        lang: lang, // or ar
        price: cost || custom_price,
        size: "narrow", // or wide, depending on the width
        theme: "default", // or can be black
        header: false, // if there is a Payment method name already
      });

      tabbyHndlr();
    } else if (paymentMethod == "tamara") {
      tamaraHndlr();
    } else if (paymentMethod == "apple_pay") {
      onApplePayButtonClicked();
    } else {
      setPaymentLoading(true);
      var formData = {
        userId: user?.id,
        spackageId: bundle?.id,
        club_id: auth?.user?.club_id,
        gym_location: user?.home_location_id,
        payment_method: "visa_master",
        product_type: bundle?.items ? "bundle" : "service_package",
        starts_at: globalFormData.starts_at,
      };
      api.post("subscription-visa-payment", formData).then((response: any) => {
        const message = response?.data?.message;
        displaySpreedlyFrom(message);
      });
    }
  };

  const submitFisikalFormSecondStepTabbyPayment = (data: any) => {
    if (data.message.url) {
      // var newWindow = window.open();

      setPaymentLoading(false);
      // window.open(data.message.url, "_blank");
      window.location.href = data.message.url;
      setOpened(false);
      setPaymentMethod("visa_master");
    } else {
      setPaymentLoading(false);
      setErrorMessage(t("ERROR"));
    }
  };

  const onApplePayButtonClicked = () => {
    setPaymentLoading(true);
    setOpened(false);
    setPaymentMethod("visa_master");

    var ApplePaySession = window?.ApplePaySession;
    if (!window?.ApplePaySession) {
      // jQuery("#apple_pay_wrap").addClass("d-none no-apple-session");
      return;
    }

    // Define ApplePayPaymentRequest
    const request = {
      countryCode: "SA",
      currencyCode: "SAR",
      merchantCapabilities: ["supports3DS"],
      supportedNetworks: ["visa", "masterCard", "mada"],
      total: {
        label: bundle?.name,
        type: "final",
        amount: cost || custom_price,
        // amount: "2.00",
      },
    };

    // Create ApplePaySession
    const session = new ApplePaySession(3, request);

    // jQuery("#payment-error-2").addClass("d-none");

    const validateMerchant = async (event: any) => {
      const data = new FormData();
      data.append("action", "applepay_validate_merchant");
      data.append("validationURL", event.validationURL);

      const response = await fetch(
        "https://www.activgyms.com/en/wp-json/api/applepay-merchant-session-request",
        {
          method: "POST",
          credentials: "same-origin",
          body: data,
        }
      );

      return await response.json();
    };

    const getCheckoutToken = async (event: any) => {
      const data = new FormData();
      data.append("action", "applepay_get_checkout_token");
      data.append(
        "applepay_token",
        JSON.stringify(event.payment.token.paymentData)
      );

      const response = await fetch(
        "https://www.activgyms.com/en/wp-json/api/applepay-get-checkout-token",
        {
          method: "POST",
          credentials: "same-origin",
          body: data,
        }
      );

      return await response.json();
    };

    const getCheckoutPaymentRequest = async (requestToken: any) => {
      const data = new FormData();
      const payment_request = { ...requestToken.message };
      payment_request.amount = request.total.amount;
      payment_request.currencyCode = request.currencyCode;
      payment_request.userData = globalFormData;

      data.append("action", "applepay_get_payment_request");
      data.append("data", JSON.stringify(payment_request));

      const response = await fetch(
        "https://www.activgyms.com/en/wp-json/api/applepay-get-payment-request",
        {
          method: "POST",
          credentials: "same-origin",
          body: data,
        }
      );

      return await response.json();
    };

    session.onvalidatemerchant = async (event: any) => {
      // Call your own server to request a new merchant session.

      const merchantSession = await validateMerchant(event);

      session.completeMerchantValidation(merchantSession.message);
    };

    session.onpaymentmethodselected = (event: any) => {
      // Define ApplePayPaymentMethodUpdate based on the selected payment method.

      const paymentMethod = event.paymentMethod;

      // No updates or errors are needed, pass an empty object.
      const update = {
        newTotal: {
          label: bundle?.name,
          type: "final",
          amount: cost || custom_price,
          // amount: "2.00",
        },
      };
      session.completePaymentMethodSelection(update);
    };

    session.onshippingmethodselected = (event: any) => {
      // Define ApplePayShippingMethodUpdate based on the selected shipping method.
      // No updates or errors are needed, pass an empty object.
      const update = {};
      session.completeShippingMethodSelection(update);
    };

    session.onshippingcontactselected = (event: any) => {
      // Define ApplePayShippingContactUpdate based on the selected shipping contact.

      const update = {};
      session.completeShippingContactSelection(update);
    };

    session.onpaymentauthorized = async (event: any) => {
      const response = await getCheckoutToken(event);
      const checkout = await getCheckoutPaymentRequest(response);
      const payment_response = checkout.message;
      const checkout_response = Array.isArray(payment_response)
        ? payment_response[0]
        : payment_response;
      // console.log("checkout_response", checkout_response);
      // console.log("checkout_response.approved", checkout_response.approved);

      if (checkout_response.approved == true) {
        session.completePayment({
          status: ApplePaySession.STATUS_SUCCESS,
        });

        navigate("/packages/success");
      } else {
        session.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
        });
        navigate(`/error?message=${checkout_response.response_summary}`);
        // setErrorMessage(payment_response.response_summary);
      }
    };

    session.oncouponcodechanged = (event: any) => {
      // console.log("oncouponcodechanged", event);
      // Define ApplePayCouponCodeUpdate
      /*
      const newTotal = calculateNewTotal(event.couponCode);
      const newLineItems = calculateNewLineItems(event.couponCode);
      const newShippingMethods = calculateNewShippingMethods(event.couponCode);
      const errors = calculateErrors(event.couponCode);
      
      session.completeCouponCodeChange({
          newTotal: newTotal,
          newLineItems: newLineItems,
          newShippingMethods: newShippingMethods,
          errors: errors,
      });*/

      session.completeCouponCodeChange({});
    };

    session.oncancel = (event: any) => {
      // Payment cancelled by WebKit
      // console.log("oncancel", event);
      setPaymentLoading(false);
      setOpened(false);
      setPaymentMethod("visa_master");
      // jQuery(".ag-loader").addClass("d-none");
      // jQuery("#payment-error-2").removeClass("d-none");
    };

    session.begin();
  };

  const cardSubmitted = () => {};
  const createPaymentRequest = (token: string) => {
    const formData = {
      token,
      userData: globalFormData,
      payment_method: "mada",
      package_name: bundle?.name,
    };

    api.post("create-payment-request-api", formData).then((response: any) => {
      submitFisikalCreateOrderSuccess(response.data);
    });
  };
  const submitFisikalFormSecondStepMadaPayment = () => {};

  function onCardTokenizationFailed(error: any) {
    Frames.enableSubmitForm();
  }

  function submitFisikalCreateOrderSuccess(data: any) {
    var response = data["message"];
    if (response.status === "Pending") {
      if (response._links.redirect.href) {
        window.location.replace(response._links.redirect.href);
      }
    }
  }

  function onCardValidationChanged(event: any) {
    // console.log("CARD_VALIDATION_CHANGED: %o", event);
    setPayButtonStatus(!Frames.isCardValid());
  }

  function onCardTokenized(event: any) {
    createPaymentRequest(event?.token);
  }

  const tabbyHndlr = function () {
    setPaymentLoading(true);
    var formData = {
      userId: user?.id,
      userName: user?.name,
      userEmail: user?.email,
      userPhone: user?.mobile_no,
      spackageId: bundle?.id,
      club_id: auth?.user?.club_id,
      gym_location: user?.home_location_id,
      payment_method: paymentMethod,
      product_type: bundle?.items ? "bundle" : "service_package",
      amount: cost || custom_price,
      userData: globalFormData,
      starts_at: globalFormData.starts_at,
    };
    // console.log("#pay-tabby");
    // api(formData, submitFisikalFormSecondStepTabbyPayment);

    api.post("subscription-tabby-payment", formData).then((response: any) => {
      submitFisikalFormSecondStepTabbyPayment(response.data);
    });
  };

  const tamaraHndlr = function () {
    setPaymentLoading(true);
    var formData = {
      userId: user?.id,
      userName: user?.name,
      userEmail: user?.email,
      userPhone: user?.mobile_no,
      spackageId: bundle?.id,
      club_id: auth?.user?.club_id,
      gym_location: user?.home_location_id,
      payment_method: paymentMethod,
      product_type: bundle?.items ? "bundle" : "service_package",
      amount: cost || custom_price,
      userData: globalFormData,
    };
    // console.log("#pay-tabby");
    // api(formData, submitFisikalFormSecondStepTabbyPayment);

    api.post("subscription-tamara-payment", formData).then((response: any) => {
      submitFisikalFormSecondStepTabbyPayment(response.data);
    });
  };

  ///////////////////////////////////////////////////////////////////////////////
  return (
    <Stack className={classes.packagesScroll} align="flex-start">
      {currentActivePackage && currentActivePackage.length !== 0 && (
        <Box>
          <Stack className={""} mb={20} justify="flex-start" spacing={0}>
            <div className="d-flex flex-column justify-content-start">
              <Title order={2} align="left">
                {t("CURRENT_PACKAGE")}
              </Title>
              <Divider
                color="#F92524"
                size="xl"
                mt={10}
                mb={10}
                sx={{ width: 84 }}
              />
            </div>
          </Stack>
          <Group position="center">
            {userServicesPackages.map((currentActivePackage: any) => (
              <Card
                sx={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: "20px",
                  padding: "20px",
                  marginTop: "20px",
                  width: 366,
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              >
                <Grid gutter="xl" justify="center">
                  <Grid.Col xs={12}>
                    <Text
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: 550,
                        fontSize: "1rem",
                      }}
                    >
                      {currentActivePackage.service_package.name}
                    </Text>
                    <Text
                      sx={{
                        textTransform: "uppercase",
                        color: "#1D1D1D",
                        fontSize: ".875rem",
                        fontWeight: 300,
                      }}
                    >
                      {currentActivePackage.service_package.description}
                    </Text>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    {/*Package Price */}
                    {currentActivePackage?.service_package?.custom_price && (
                      <Grid justify="space-between">
                        <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                          <Text size="xs">{t("packagePrice")}</Text>
                        </Grid.Col>
                        <Grid.Col xs={6}>
                          <Text size="xs" sx={{ color: "#919191" }}>
                            {currentActivePackage?.service_package?.custom_price
                              ?.amount / 100}{" "}
                            SAR
                          </Text>
                        </Grid.Col>
                      </Grid>
                    )}

                    {/*Usage */}
                    <Grid justify="space-between">
                      <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                        <Text size="xs">{t("usage")}</Text>
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Text size="xs" sx={{ color: "#919191" }}>
                          {`${currentActivePackage.used_credits} / ${currentActivePackage.total_credits}`}
                        </Text>
                      </Grid.Col>
                    </Grid>

                    {/*Purchase Date */}
                    <Grid justify="space-between">
                      <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                        <Text size="xs">{t("startDate")}</Text>
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Text size="xs" sx={{ color: "#919191" }}>
                          {format(
                            new Date(currentActivePackage.assigned_at),
                            "yyyy-MM-dd"
                          )}
                        </Text>
                      </Grid.Col>
                    </Grid>

                    {/*Exipry Date */}
                    <Grid justify="space-between">
                      <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                        <Text size="xs">{t("expiryDate")}</Text>
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Text size="xs" sx={{ color: "#919191" }}>
                          {format(
                            new Date(currentActivePackage.expires_at),
                            "yyyy-MM-dd"
                          )}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                </Grid>
              </Card>
            ))}
          </Group>
        </Box>
      )}

      {isFetched &&
        (data?.service_packages || data?.bundles) &&
        data?.count != 0 && (
          <Stack
            className={""}
            mb={10}
            mt={20}
            justify="flex-start"
            spacing={0}
          >
            <div className="d-flex flex-column justify-content-start">
              <Title order={2} align="left">
                {t("PACKAGES_SUBTITLE")}
              </Title>
              <Divider color="#F92524" size="xl" mt={7} sx={{ width: 84 }} />
            </div>
          </Stack>
        )}

      <Group position="center">
        {isFetched &&
          data?.bundles
            ?.sort(sortByOrderTag)
            ?.map((bundle: any, index: number) => (
              <PackagesCard
                bundle={bundle}
                setOpened={setOpened}
                setBundle={setBundle}
                key={index}
                type="bundle"
              />
            ))}
        {isLoading && <Loader></Loader>}
      </Group>
      <Group position="left">
        {isFetched &&
          data?.service_packages
            ?.sort(sortByOrderTag)
            ?.map((bundle: any, index: number) => (
              <PackagesCard
                bundle={bundle}
                setOpened={setOpened}
                setBundle={setBundle}
                key={index}
                type="package"
              />
            ))}
      </Group>

      {ptIsFetched && packages?.service_packages && (
        <>
          <Stack
            className={""}
            mb={10}
            mt={20}
            justify="flex-start"
            spacing={0}
          >
            <div className="d-flex flex-column justify-content-start">
              <Title order={2} align="left">
                {t("PERSONAL_TRAINING")}
              </Title>
              <Divider color="#F92524" size="xl" mt={7} sx={{ width: 84 }} />
            </div>
          </Stack>

          <Group position="left">
            {ptIsFetched &&
              packages?.service_packages.map((bundle: any) => (
                <PackagesCard
                  bundle={bundle}
                  setOpened={setOpened}
                  setBundle={setBundle}
                  type="pt_package"
                />
              ))}
            {ptIsFetched && packages?.service_packages.length == 0 && (
              <>No PT Packages Found</>
            )}
            {ptIsLoading && <Loader></Loader>}
          </Group>
        </>
      )}

      <Modal
        opened={opened}
        onClose={() => {
          setPaymentMethod("visa_master");
          setOpened(false);
          setPaymentLoading(false);
        }}
        centered
        size={largeScreen ? "50%" : "100%"}
        overlayColor="white"
        overlayOpacity={0}
        overlayBlur={0}
        closeOnClickOutside={true}
        lockScroll={false}
        styles={{
          modal: {
            backgroundColor: "rgba(255,255,255,1)",
            border: "1px solid grey",
          },

          close: {
            backgroundColor: "#F1563C",
            color: "white",
            width: 45,
            height: 45,
            borderRadius: 100,
            position: "relative",
            bottom: 35,
            left: 20,
            "&:hover": {
              backgroundColor: "#F1563C",
            },
          },
        }}
      >
        <Stack justify="start" align="center">
          <Stack mb={10} justify="flex-start" spacing={0}>
            <div className="d-flex flex-column justify-content-start purchase-modal-title">
              <Title order={2}>{bundle?.name}</Title>
              <Title order={2}>
                {t("Amount")}: {cost || custom_price} {t("SAR")}
              </Title>
              {currentActivePackage && !bundle?.pt_package && (
                <Title order={2}>
                  {t("START_DATE")}:
                  <Text size="md" color="orange" mx={10}>
                    {globalFormData.starts_at}
                  </Text>
                </Title>
              )}

              <Title order={2} className="mt-3">
                {t("select_payment_method")}
              </Title>
              <Divider
                color="#F92524"
                size="xl"
                mt={25}
                sx={{ width: 84, alignSelf: "center" }}
              />
            </div>
          </Stack>
          <div className="payment-method-wrapper">
            <Stack className="d-flex flex-column justify-content-center align-items-center payments-main-wrapper">
              <SegmentedControl
                color="orange"
                value={paymentMethod}
                onChange={setPaymentMethod}
                data={[
                  { label: "Visa / Master", value: "visa_master" },
                  {
                    label: "Mada",
                    value: "mada_spreedly",
                  },
                  {
                    label: "Tabby",
                    value: "tabby",
                    disabled: isMonthlyRecurringBundle,
                  },
                  {
                    label: "Tamara",
                    value: "tamara",
                    disabled: isMonthlyRecurringBundle,
                  },
                  {
                    label: "Apple Pay",
                    value: "apple_pay",
                    disabled:
                      typeof window?.ApplePaySession === "undefined" ||
                      window?.ApplePaySession === null ||
                      isMonthlyRecurringBundle,
                  },
                ]}
              />
              <div
                className={`${displayTabby ? "" : "d-none"}`}
                id="tabbyCard"
              ></div>
              <form
                id="payment-form"
                method="POST"
                onSubmit={(event) => {
                  console.log("test");
                  event.preventDefault();
                  Frames.submitCard();
                }}
              >
                {paymentMethod === "mada" && (
                  <div className={`one-liner ${displayMada ? "" : "d-none"}`}>
                    <div className="card-frame"></div>
                    <Button
                      className={`${displayMada ? "" : "d-none"}`}
                      id="pay-button"
                      disabled={payButtonStatus}
                      type="submit"
                    >
                      {t("Pay")} {cost || custom_price} {t("SAR")}
                    </Button>
                  </div>
                )}
              </form>
              <p>{errorMessage}</p>
              <Button
                style={{ marginTop: "20px", marginBottom: "20px" }}
                onClick={handlePayment}
                disabled={false}
                loading={paymentLoading}
              >
                {t("proceed")}
              </Button>
            </Stack>
          </div>
        </Stack>
      </Modal>
      <Modal
        opened={m3dsModal}
        onClose={() => {
          setPaymentLoading(false);
          setm3dsModal(false);
        }}
        centered
        size={largeScreen ? "md" : "100%"}
        overlayColor="white"
        overlayOpacity={0}
        overlayBlur={0}
        closeOnClickOutside={false}
        lockScroll={false}
        styles={{
          modal: {
            backgroundColor: "rgba(255,255,255,1)",
            border: "1px solid grey",
          },

          close: {
            backgroundColor: "#F1563C",
            color: "white",
            width: 45,
            height: 45,
            borderRadius: 100,
            position: "relative",
            bottom: 35,
            left: 20,
            "&:hover": {
              backgroundColor: "#F1563C",
            },
          },
        }}
      >
        <Stack justify="center" align="center">
          <Stack>
            <div
              id="3ds-modal-body"
              style={{
                position: "relative",
                flex: "1 1 auto",
                padding: "1rem",
                width: "100%",
              }}
            >
              <div id="device-fingerprint" className="hidden"></div>
              <div id="challenge-modal">
                <div id="challenge"></div>
              </div>
            </div>
            {payment3dsLoading && <Loader></Loader>}
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
}

export default Packages;
